

















































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import FormCheckbox from '@/components/form/form-checkbox.vue';
import { copyObject,
  downloadFile,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  onCurrencyFocus,
  unselectFirstElement } from '@/helper/functions';
import apiBackend from '@/service/api-insurance';
// import {getTabsHelper} from '@/helper/functions';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import VersicherungsortRow from '@/views/components/elektronik-insurance/versicherungsort-row.vue';


const geltungsbereichItem = {
  num: 0,
  isSelected: false,
  Strabe: '',
  PLZ: '',
  Ort: '',
  Land: '',
  Hausnummer: '',
  ZursZone: '',
  Starkregenzone: '',
  Naturgefahren2: '',
  Naturgefahren3: '',
  geltungsbereich: '',
  individualAgreements: '',
};

type erstrisikosummenAdditionalItemType = {
  name: string | null;
  ERS: string | null;
  geanderteERS: string | null;
};

const erstrisikosummenItem = {
  name: '',
  ERS: '',
  geanderteERS: '',
};
const erstrisikosummenType = 2;

export default Vue.extend({
  name: 'elektronik-insurance-Praxispolice',
  mixins: [dataChangeMixin],
  components: {
    FormCheckbox,
    VersicherungsortRow,
  },
  data() {
    return {
      name: 'elektronik-insurance-Praxispolice',
      key: 'ELEKTRONIK_INSURANCE',
      versicherungsortItems: [
        {
          ...geltungsbereichItem,
        },
      ],
      mitversicherteGerategruppen: {
        hardware: {
          name: 'Hardware',
          versicherungssumme: 0,
          isSelected: false,
          bewertungsjahr: '',
          additionalCheckboxName: 'mit Endoskopie',
          isSelectedAdditionalCheckbox: false,
        },
        inhalt: {
          name: 'Inhalt',
          versicherungssumme: null,
          isSelected: false,
          bewertungsjahr: '',
        },
        aubendeckung: null,
        bemerkungenHardware: '',
      },
      selbstbeteiligungHardware: {
        tarif: {
          name: 'Tarif - SB',
          SB: null,
          SBPercentage: '',
          SBMin: '',
          SBMax: '',
        },
        variant1: {
          name: 'SB-Variante 1',
          SB: null,
          SBPercentage: '',
          SBMin: '',
          SBMax: '',
        },
        variant2: {
          name: 'SB-Variante 2',
          SB: null,
          SBPercentage: '',
          SBMin: '',
          SBMax: '',
        },
        variant3: {
          name: 'SB-Variante 3',
          SB: null,
          SBPercentage: '',
          SBMin: '',
          SBMax: '',
        },
      },
      software: {
        versicherungssumme: 25000,
        aubendeckung: 50,
        lagerungSicherheitsdatentrager: '',
        bemerkungenSoftware: '',
      },
      selbstbeteiligungSoftware: {
        tarif: {
          percentage: null,
          min: null,
        },
      },
      mehrkostenErtragsausfall: {
        selectValue: null,
        versicherungssumme: 25000,
        selbstbeteiligung: 2,
        haftzeit: '12 Monate',
        aubendeckung: 50,
        bemerkungenMehrkostenErtragsausfall: '',
      },
      selbstbeteiligungHaftzeitMehrkosten: {
        tarif: {
          name: 'Tarif - SB',
          SB: null,
          haftzeit: null,
        },
        items: [
          {
            name: 'SB-Variante 1',
            SB: null,
            haftzeit: null,
          },
          {
            name: 'SB-Variante 2',
            SB: null,
            haftzeit: null,
          },
          {
            name: 'SB-Variante 3',
            SB: null,
            haftzeit: null,
          },
        ],
      },
      deckungsanpassungen: {
        items: {
          ausschlussFeuerGemab: {
            name: 'Ausschluss Feuer gemäß (Nr. 1.2.)',
            value: 'Nein',
          },
          ausschlussEinbruchdiebstahlGemab: {
            name: 'Ausschluss Einbruchdiebstahl gemäß (Nr. 1.2.)',
            value: 'Nein',
          },
          ausschlussDiebstahlGemab: {
            name: 'Ausschluss Abhandenkommen gemäß (Nr. 1.2.)',
            value: 'Nein',
          },
          ausschlussLeitungswasserGemab: {
            name: 'Ausschluss Leitungswasser gemäß (Nr. 1.2.)',
            value: 'Nein',
          },
          ausschlussUberschwemmung: {
            name: 'Ausschluss Überschwemmung',
            value: 'Nein',
          },
          ausschlussSturm: {
            name: 'Ausschluss Sturm',
            value: 'Nein',
          },
        },
        einschlussErdbeben: 'Nein',
        selbstbeteiligung: null,
        JHE: null,
      },
      erstrisikosummen: {
        items: {
          aufraumungsBergungsDekontaminationsUndEnsorgungskosten: {
            name: 'Aufräumungs-, Bergungs-, Dekontaminations- und Entsorgungskosten',
            ERS: null,
            geanderteERS: null,
          },
          dekontaminationsUndEntsorgungskostenFurErdreich: {
            name: 'Dekontaminations- und Entsorgungskosten für Erdreich',
            ERS: null,
            geanderteERS: null,
          },
          bewegungsUndSchutzkosten: {
            name: 'Bewegungs- und Schutzkosten',
            ERS: null,
            geanderteERS: null,
          },
          luftfrachtkosten: {
            name: 'Luftfrachtkosten',
            ERS: null,
            geanderteERS: null,
          },
          bergungskosten: {
            name: 'Bergungskosten',
            ERS: null,
            geanderteERS: null,
          },
          kostenFurErdPflasterMauerUndStemmarbeiten: {
            name: 'Kosten für Erd-, Pflaster-, Mauer- und Stemmarbeiten',
            ERS: null,
            geanderteERS: null,
          },
        },
        additionItems: [] as erstrisikosummenAdditionalItemType[],
      },
      byErstrisikosummenType: erstrisikosummenType,
      notizen: '',
      pdfStyles: false,
      byVersicherungsortType: 1,
      refImportPlacesFileName: 'excelImportPlaces',
      loadedData: false,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id', 'productId', 'processSavingData'],
  watch: {
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.basicData && this.firstOpenTab) {
        this.fillVersicherungsortItemsFromGruppendaten(this.insuranceData.basicData.versicherungsort);
        this.loadedData = true;
      }
    },
  },
  methods: {
    onCurrencyFocus,
    goToBackUrl() {
      if (this.prevTabItem) {
        this.$emit('go-back-tab', this.prevTabItem.path, 'insuranceForms', this.prevTabItem.key);
      } else {
        this.$emit('go-back-tab', 'elektronik-insurance', 'insuranceForms', 1);
      }
    },
    uploadPlacesFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-places-excel`, 'import', file).then((response: any) => {
          this.importPlacesFromExcel(response.data);
          this.excelImportPlacesRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    importPlacesFromExcel(dataItems: any[]) {
      const firstElementEmpty = this.versicherungsortItems.length === 1 &&
        !this.versicherungsortItems[0].Strabe &&
        !this.versicherungsortItems[0].PLZ &&
        !this.versicherungsortItems[0].Ort &&
        !this.versicherungsortItems[0].Land;

      dataItems.forEach((item, i) => {
        const fillFirst = firstElementEmpty && i === 0;

        const newItem = fillFirst ? this.versicherungsortItems[0] : copyObject(geltungsbereichItem);

        newItem.Strabe = item.street;
        newItem.PLZ = item.postCode;
        newItem.Ort = item.place;
        newItem.Land = item.land;

        if (!fillFirst) {
          this.versicherungsortItems.push(newItem);
        }
      });
    },
    exportPlacesData() {
      const data = this.versicherungsortItems.map((itm) => ({
        street: itm.Strabe,
        code: itm.PLZ,
        city: itm.Ort,
        land: itm.Land,
      }));

      apiBackend.exportPlacesData(data).then((response) => {
        downloadFile(response.data, 'Versicherungsort-Praxispolice.xlsx');
      });
    },
    chooseImportPlacesFile() {
      this.excelImportPlacesRef.click();
    },
    downloadInsurancePlacesTemplate() {
      apiBackend.downloadInsurancePlaces().then((response) => {
        downloadFile(response.data, 'Einlesen von Versicherungsorten.xlsx');
      });
    },
    fillVersicherungsortItemsFromGruppendaten(versicherungsort: any) {
      this.versicherungsortItems[0].Strabe = versicherungsort.Strabe;
      this.versicherungsortItems[0].PLZ = versicherungsort.PLZ;
      this.versicherungsortItems[0].Ort = versicherungsort.Ort;
      this.versicherungsortItems[0].ZursZone = versicherungsort.ZursZone;
      this.versicherungsortItems[0].Hausnummer = versicherungsort.Hausnummer;
      this.versicherungsortItems[0].Starkregenzone = versicherungsort.Starkregenzone;
    },
    exportToPDF() {
      const filename = `Zusammenfassung-ELEK-Praxispolice-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    deleteErstrisikosummenItem(key) {
      this.erstrisikosummen.additionItems.splice(key, 1);
    },
    dublicateRow(fillData: boolean, type: number) {
      switch (type) {
        case this.byVersicherungsortType:
          duplicateDataItems(geltungsbereichItem, this.versicherungsortItems, fillData);
          break;
        case this.byErstrisikosummenType:
          duplicateDataItemsWithoutSelect(erstrisikosummenItem, this.erstrisikosummen.additionItems);
          break;
        default:
          console.log('Wrong type');
      }
    },
    deleteRows(type: number) {
      switch (type) {
        case this.byVersicherungsortType:
          unselectFirstElement(this.versicherungsortItems);

          this.versicherungsortItems = this.versicherungsortItems.filter((item) => !item.isSelected);
          break;
        default:
          console.log('Wrong type');
      }
    },
    onSubmit(goNextPage = true) {
      const data = {
        versicherungsortItems: this.versicherungsortItems,
        mitversicherteGerategruppen: this.mitversicherteGerategruppen,
        selbstbeteiligungHardware: this.selbstbeteiligungHardware,
        software: this.software,
        selbstbeteiligungSoftware: this.selbstbeteiligungSoftware,
        mehrkostenErtragsausfall: this.mehrkostenErtragsausfall,
        selbstbeteiligungHaftzeitMehrkosten: this.selbstbeteiligungHaftzeitMehrkosten,
        deckungsanpassungen: this.deckungsanpassungen,
        erstrisikosummen: this.erstrisikosummen,
        notizen: this.notizen,
      };
      if (goNextPage) {
        this.$emit('insurance-forms-data-change', data, 'Praxispolice', this.nextTabId);
      } else {
        this.$emit('insurance-forms-data-change', data, 'Praxispolice', 5);
      }
      // this.$emit('price-card-data', data2, this.price, this.noPrice);
    },
    setValues(insuranceData: any) {
      this.name = insuranceData.name;
      this.versicherungsortItems = insuranceData.versicherungsortItems;
      this.mitversicherteGerategruppen = insuranceData.mitversicherteGerategruppen;
      this.selbstbeteiligungHardware = insuranceData.selbstbeteiligungHardware;
      this.software = insuranceData.software;
      this.selbstbeteiligungSoftware = insuranceData.selbstbeteiligungSoftware;
      this.mehrkostenErtragsausfall = insuranceData.mehrkostenErtragsausfall;
      this.selbstbeteiligungHaftzeitMehrkosten = insuranceData.selbstbeteiligungHaftzeitMehrkosten;
      this.deckungsanpassungen = insuranceData.deckungsanpassungen;
      this.erstrisikosummen = insuranceData.erstrisikosummen;
      this.notizen = insuranceData.notizen;
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData && this.insuranceData.insuranceFormsData && this.insuranceData.insuranceFormsData.Praxispolice) {
      this.setValues(this.insuranceData.insuranceFormsData.Praxispolice);
    }
    (this as any).startWatcherActivated();
  },
  computed: {
    prevTabItem(): any {
      return this.$store.getters['tabsModule/getPrevTab']('insuranceForms', 5);
    },
    firstOpenTab(): boolean {
      // eslint-disable-next-line no-prototype-builtins
      return (this.insuranceData === null || this.insuranceData.insuranceFormsData === null || !this.insuranceData.insuranceFormsData.hasOwnProperty('Praxispolice')) && (!this.loadedData);
    },
    showPlusBtn(): boolean {
      return (this.erstrisikosummen.additionItems.length < 5);
    },
    showMinusBtn(): boolean {
      return (this.erstrisikosummen.additionItems.length > 1);
    },
    nextTabId() {
      return 6;
    },
    excelImportPlacesRef(): any {
      return this.$refs[this.refImportPlacesFileName];
    },
  },
});
